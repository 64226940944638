<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base">
      <vx-card title="Information" class="mb-5">
      <div>
          <vs-button style='float: right' type='filled' color='primary' :to='{name:"price_lists-edit", params:{id:$route.params.id}}'>Edit</vs-button>
      </div>
        <div class="flex">

          <table class="info sm:w-full md:w-1/2">
            <tr>
              <td class="font-semibold">Name</td>
              <td>{{ dt.name }}</td>
            </tr>
            <tr>
              <td class="font-semibold">Route</td>
              <td>{{dt.route}}</td>
            </tr>
            <tr>
              <td class="font-semibold">Commodity</td>
              <td>{{dt.commodity}}</td>
            </tr>
            <tr>
              <td class="font-semibold">Vehicle Type</td>
              <td>{{dt.vehicle_type}}</td>
            </tr>
            <tr>
              <td class="font-semibold">Price Per Kg</td>
              <td>{{dt.price_tonase|numFormat}}. Minimal ({{ dt.min_tonase }})</td>
            </tr>
            <tr>
              <td class="font-semibold">Price Per m<sup>3</sup></td>
              <td>{{dt.price_volume|numFormat}}. Minimal ({{ dt.min_volume }})</td>
            </tr>
            <tr>
              <td class="font-semibold">Price Per Item</td>
              <td>{{dt.price_item|numFormat}}. Minimal ({{ dt.min_item }})</td>
            </tr>
            <tr>
              <td class="font-semibold">Price Full (Wholesome)</td>
              <td>{{dt.price_full|numFormat}}</td>
            </tr>
            <tr>
              <td class="font-semibold">Status</td>
              <td>{{dt.is_active ? 'Active' : 'Inactive'}}</td>
            </tr>
            <tr>
              <td class="font-semibold">Description</td>
              <td>{{dt.description}}</td>
            </tr>
          </table>
          
        </div>
      </vx-card>
      
    </div>
    <div class="vx-col w-full mb-base">
      <vx-card title="Basic Cost">
        <template slot="actions">
          <vs-button type="gradient" :to="{name: 'price_lists-add-cost', params:{id: $route.params.id}}">Add Cost</vs-button>
        </template>
        <vs-table :data="cost_lists" class="mb-3">
          <template slot="thead">
            <vs-th>Name</vs-th>
            <vs-th>Vendor</vs-th>
            <vs-th>Qty</vs-th>
            <vs-th>Cost</vs-th>
            <vs-th>Total Cost</vs-th>
            <vs-th>Description</vs-th>
            <vs-th></vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :key="i" v-for="(tr, i) in data">
              <vs-td>{{tr.name}}</vs-td>
              <vs-td label="Vendor">{{tr.vendor}}</vs-td>
              <vs-td label="Qty" class='text-right'>{{tr.qty|numFormat}}</vs-td>
              <vs-td label="Cost" class='text-right'>{{tr.price|numFormat}}</vs-td>
              <vs-td label="Total Cost" class='text-right'>{{tr.total_price|numFormat}}</vs-td>
              <vs-td label="Description">{{tr.description}}</vs-td>
              <vs-td width="10">
                <div class="flex">
                  <vs-button size="small" type="line" :to="{name:'price_lists-edit-cost', params:{id : $route.params.id,cost_id: tr.id}}" icon-pack="feather" icon="icon-edit"></vs-button>
                  <vs-button  size="small" type="line" icon-pack="feather" icon="icon-trash" color="danger" @click="deleteCost(tr.id)"></vs-button>
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data(){
    return {
      dt: {},
      details: [],
      cost_lists: [],
      idDeleteCost: null,
      idApproveCost: null,
    }
  },
  methods:{
    ...mapActions({
      dispatchShow: 'price_lists/show',
      dispatchShowCosts: 'price_lists/get_costs'
    }),
    async getData(){
      let { data } = await this.dispatchShow(this.$route.params.id)
      this.dt = data
    },
    async getCosts(){
      let { data } = await this.dispatchShowCosts(this.$route.params.id)
      this.cost_lists = data
    },
    approveCost(id){
      this.idApproveCost = id
      this.$vs.dialog({
        type: `confirm`,
        color: `primary`,
        title: `Are you sure ?`,
        text: `This cost will be approved.`,
        accept: this.confirmApproveCost
      })
    },
    async confirmApproveCost(){
      try {
        await this.$store.dispatch('price_lists/approve_costs', this.idApproveCost)
        this.getCosts()
        this.$vs.notify({
          title: `Success`,
          text: `Your data has been approved successfully`,
          color: `primary`
        })
      } catch (error) {
        this.$vs.notify({
          title: `Oops!`,
          text: `Looks like something went wrong. please try again later (${error.data.message})`,
          color: `danger`
        })
      }
    },
    deleteCost(id){
      this.idDeleteCost = id
      this.$vs.dialog({
        type: `confirm`,
        color: `danger`,
        title: `Are you sure ?`,
        text: `Deleted data can no longer be restored`,
        accept: this.confirmDeleteCost
      })
    },
    async confirmDeleteCost(){
      try {
        await this.$store.dispatch('price_lists/delete_costs', this.idDeleteCost)
        this.getCosts()
        this.$vs.notify({
          title: `Success`,
          text: `Your data has been deleted successfully`,
          color: `primary`
        })
      } catch (error) {
        this.$vs.notify({
          title: `Oops!`,
          text: `Looks like something went wrong. please try again later (${error.data.message})`,
          color: `danger`
        })
      }
    },
  },
  mounted(){
    this.getData()
    this.getCosts()
  }
}
</script>

<style>

</style>